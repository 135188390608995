import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/common.scss";
import { Dialog } from "element-ui";

Vue.config.productionTip = false;
// 按需引入
Vue.use(Dialog);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// title

// title
