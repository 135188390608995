var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"youjiaopage_wrap"},[_c('nav2'),_c('banner2',{attrs:{"bannerH":_vm.h1,"bannerP":_vm.p1,"bannerImg":_vm.Img1}}),_vm._m(0),_c('div',{staticClass:"NewsDetails"},[_c('div',{staticClass:"NewsDetails_cont"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('Next',{ref:"childData"}),_vm._m(4),_vm._m(5)],1)]),_c('footer2')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Crumbs"},[_c('div',[_vm._v("当前位置：首页>"),_c('span',[_vm._v("隐私政策")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NewsDetails_cont_title"},[_c('h1',[_vm._v("狸小住用户隐私政策")]),_c('div',{staticClass:"NewsDetails_cont_title_span"},[_c('img',{attrs:{"src":require("../../assets/img/newsDetail/矩形.png"),"alt":""}}),_c('span',[_vm._v("发布时间：2019-11-15   ")]),_c('img',{attrs:{"src":require("../../assets/img/newsDetail/矩形(1).png"),"alt":""}}),_c('span',[_vm._v("浏览次数：12345 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NewsDetails_cont_text"},[_c('img',{attrs:{"src":require("../../assets/img/privacy/worker.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NewsDetails_cont_img"},[_c('img',{attrs:{"src":require("../../assets/img/newsDetail/摄图网_401521307_banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NewsDetails_cont_btm"},[_c('div',[_vm._v("相关新闻")]),_c('p',[_vm._v("更多 >")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NewsDetails_cont_btm2"},[_c('div',{staticClass:"NewsDetails_cont_btm2_1"},[_c('div',[_vm._v("发展数字经济 共享包容增长 为经济发展插上“数字翅膀”")]),_c('p',[_vm._v("2021-05-30")]),_c('p',[_vm._v(" 中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长... ")])]),_c('div',{staticClass:"NewsDetails_cont_btm2_1",staticStyle:{"background":"#f8f8f8"}},[_c('div',[_vm._v("发展数字经济 共享包容增长 为经济发展插上“数字翅膀”")]),_c('p',[_vm._v("2021-05-30")]),_c('p',[_vm._v(" 中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长... ")])])])
}]

export { render, staticRenderFns }