import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: "数字化生态系统",
    pageCount: 8,
    selected: 0,
    navList: [
      { title: "首页", url: "/" },
      { title: "服务保障", url: "/serviceAssurance" },
      { title: "蓝友联盟", url: "/blueUnion" },
      { title: "设计师联盟", url: "/designerUnion" },
      { title: "供应商管理", url: "/supplierManage" },
      { title: "关于我们", url: "/aboutUs" },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
});
