var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"youjiaopage_wrap manage"},[_c('nav2'),_c('banner2',{staticClass:"banner",attrs:{"bannerH":"供应商管理","bannerP":_vm.bannerP,"bannerImg":require('@/assets/img/top_back.png')}}),_c('div',{staticClass:"main"},[_c('h1',{staticClass:"box-title"},[_vm._v("狸小住商家平台")]),_c('div',{staticClass:"platform flex"},_vm._l((_vm.platformList),function(item,index){return _c('div',{key:index,staticClass:"platform-item flex"},[_c('div',{staticClass:"platform-item-left flex1 flex flex-dr flex-a flex-jc"},[_c('div',{staticClass:"platform-item-top"},[_vm._v(_vm._s(item.topContent))]),_c('div',{staticClass:"platform-item-bottom"},[_vm._v(" "+_vm._s(item.bottomContent)+" ")])]),_c('div',{staticClass:"platform-item-right flex1"},[_c('img',{attrs:{"src":item.icon,"alt":""}})])])}),0),_c('h1',{staticClass:"box-title"},[_vm._v("店铺与经营")]),_c('div',{staticClass:"operate flex"},_vm._l((_vm.operate),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('img',{staticClass:"icon",attrs:{"src":item.icon,"alt":""}}),_c('div',[_vm._v(_vm._s(item.text))])])}),0),_c('div',{staticClass:"data-board flex"},[_c('div',{staticClass:"data-board-left"},[_c('div',{staticClass:"data-board-title"},[_vm._v("经营数据看板")]),_c('div',{staticClass:"data-board-text"},[_vm._v(" 提供直观的经营数据展示，诊断店铺经营健康状况，挖掘潜在用户，有效链接您的生意与真实业主 ")]),_c('div',{staticClass:"data-board-steps"},_vm._l((_vm.dataBoardStepList),function(item,index){return _c('div',{key:index,staticClass:"item",style:({
              borderRight:
                index !== _vm.dataBoardStepList.length - 1
                  ? ' solid 1px #707070'
                  : 'none',
            })},[_c('span',{staticClass:"item-1"},[_vm._v("0"+_vm._s(index + 1))]),_c('span',{staticClass:"item-2"},[_vm._v("+")]),_c('span',{staticClass:"item-3"},[_vm._v(_vm._s(item))])])}),0)]),_vm._m(0)])]),_c('BottomStepsFull',{attrs:{"title":"认证步骤","stepList":_vm.bottomStepList,"itemSpace":84}}),_c('footer2')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-board-right"},[_c('img',{attrs:{"src":require("@/assets/img/supplierManage/data_board_right.png"),"alt":""}})])
}]

export { render, staticRenderFns }