<template>
  <!-- star -->

  <div class="homepage_wrap">
    <!-- banner图和导航栏 -->
    <div class="banner_nav">
      <!-- banner -->
      <div class="banner_nav_bg">
        <img src="../assets/img/home/home_top_background.png" alt="" />
      </div>
      <!-- 导航栏 -->
      <div class="navWrap">
        <div class="navWrap_center">
          <div class="navWrap_center_img">
            <img src="../assets/img/logo_plain.png" alt="" />
          </div>

          <!-- <div class="dropdown">
            <button class="dropbtn" @click="funRouter">集团产业</button>
            <div class="dropdown-content">
              <router-link to="/keji">科技</router-link>
              <router-link to="/zhijiao">职教</router-link>
              <router-link to="/youjiao">幼教</router-link>
              <router-link to="/dianshang">电商</router-link>

              <router-link to="/jinrong">金融</router-link>
            </div>
          </div> -->

          <ul class="navWrap_center_ul">
            <li
              v-for="(item, index) in navList"
              :key="index"
              @click="funyuming(item.url)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
      <!-- title -->
      <div class="banner_title_wrap">
        <div class="banner_title_center">
          <div class="title">{{ title }}</div>
          <div class="content">
            同城自装服务平台，找设计，找工地管家，找工匠，买材料就找狸小住；
          </div>
          <div class="content">好设计、好管家、好工匠、好服务。</div>
        </div>
      </div>
    </div>
    <!-- 装修攻略、行业动态 -->
    <div class="news">
      <div class="news_cont">
        <!-- left -->
        <div class="news_cont_left">
          <h1>装修攻略、行业动态</h1>
          <div class="news_bg">
            <div class="news_cont_left_img">
              <img src="../assets/img/news_img1.png" alt="" />
            </div>
            <div class="news_cont_left_text">
              <h1 class="title">数字化生态系统</h1>
              <p>2024-07-08</p>
              <p>
                融合多年一线装企数字化服务经验，借助先进信息技术的应用，重构传统装修、基
              </p>
              <p>
                于直连用户+数据驱动+线上
                智能引导+线下消费服务和材料，助力客户与蓝领......
              </p>
            </div>
          </div>
        </div>
        <!-- right -->
        <div class="news_cont_right">
          <div class="news_cont_right_text">
            <div
              class="news_cont_right_text_item news_cont_right_text_hr"
              v-for="(item, index) in newList"
              :key="index"
              @click="newsArticle(index)"
            >
              <h1 class="title">{{ item.title }}</h1>
              <p>{{ item.time }}</p>
              <p>
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 集团产业 -->
    <div class="GroupIndustry">
      <div class="GroupIndustry_cont">
        <!-- 左 -->
        <div class="GroupIndustry_cont_left">
          <!-- title -->
          <div class="GroupIndustry_cont_left_title">
            <p>服务指南</p>
          </div>
          <!-- img -->
          <div class="GroupIndustry_cont_left_img_f">
            <div
              v-for="(item, index) in jituanList"
              :key="index"
              class="GroupIndustry_cont_left_img"
            >
              <img
                :src="
                  require(`../assets/img/home/home_server_${index + 1}.png`)
                "
              />
              <div class="GroupIndustry_cont_left_img_shadow"></div>
              <div class="GroupIndustry_cont_left_text">
                <span>{{ item.jituanspan }}</span>
                <p>{{ item.jituanp }}</p>
              </div>
            </div>
          </div>
          <!-- right -->
        </div>
        <!-- right -->
        <div class="GroupIndustry_cont_right">
          <!-- title -->
          <div class="GroupIndustry_cont_right_img">
            <img src="../assets/img/home/home_server_5.png" alt="" />
            <div class="GroupIndustry_cont_left_img_shadow"></div>
          </div>
          <div class="GroupIndustry_cont_right_text">
            <p>完成全房装修</p>
            <span>Completion of project</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于企业 -->
    <div class="AboutEnterprises">
      <div class="AboutEnterprises_cont">
        <!-- top -->
        <div class="AboutEnterprises_cont_top">
          <!-- top_left -->
          <div class="AboutEnterprises_cont_top_left">
            <h1>数字生态系统</h1>
            <p>
              融合多年一线装企数字化服务经验，借助先进信息技术的应用，重构传统装修、基于直连用户+数据驱动+线上
              智能引导+线下消费服务和材料，助力客户与蓝领之前对工地项目管理等核心环节，去掉传统装修层层转包的中间环节实行业主直连工匠。
            </p>
            <router-link to="/jituangaikuo">
              <div class="AboutEnterprises_cont_top_left_btn">
                <span>了解更多</span>
                <img src="../assets/img/guanyujiantou.png" alt="" />
              </div>
            </router-link>
          </div>
          <!-- top_right -->
          <div class="AboutEnterprises_cont_top_right">
            <img src="../assets/img/guanyuyou.png" alt="" />
            <div></div>
          </div>
        </div>
        <!-- bottom -->
        <div class="AboutEnterprises_cont_bottom">
          <div
            class="AboutEnterprises_cont_bottom_item"
            v-for="(item, index) in systemBottomList"
            :key="index"
          >
            <h1>0{{ index + 1 }}</h1>

            <div class="AboutEnterprises_cont_bottom_item2">
              <span>+</span>
              <p>{{ item }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="CooperativePartner">
      <div class="CooperativePartner_cont">
        <h1 class="CooperativePartner_cont_title">合作伙伴</h1>
      </div>
      <div class="CooperativePartner_cont_center">
        <div class="CooperativePartner_cont_center_item">
          <img src="../assets/img/合作1.png" alt="" />
          <span>合作商家</span>
        </div>
        <div class="CooperativePartner_cont_center_item">
          <img src="../assets/img/合作2.png" alt="" />
          <span>合作厂家</span>
        </div>
      </div>
      <div class="CooperativePartner_cont_bottom">
        <div
          v-for="(item, index) in logoList"
          :key="index"
          class="CooperativePartner_cont_bottom_logo"
        >
          <img :src="item.logo" alt="" />
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
export default {
  name: "index",
  components: {
    footer2,
  },
  data() {
    return {
      logoimg: require("../assets/img/lALPDhmOw39u8LZszQFq_362_108.png_720x720q90g.jpg"),
      jituanList: [
        {
          jituanspan: "呼叫工长上门",
          jituanp: "Call master door to door",
        },
        {
          jituanspan: "出具费用清单",
          jituanp: "Statement of expenses",
        },
        {
          jituanspan: "确定购买启动施工",
          jituanp: "Purchase and start",
        },
        {
          jituanspan: "逐项验收确认",
          jituanp: "Acceptance Confirmation",
        },
      ],
      logoList: [],
      newList: [
        {
          title: "家装半包：装修步骤和自购清单全攻略！”",
          time: "2024-07-08",
          text: "家里装修前。请提前1-3个月做准备。准备工作做的越充分，你装修时遇到的烦恼和意外就越少。你花的冤枉钱也就越少，千万不要拿到房就匆忙找装修公司谈......",
        },
        {
          title: "如何装修房子最省钱？",
          time: "2024-07-08",
          text: "自己装修了几套房子，经验就是装修不能带着省钱的想法。跟装修队长斗智斗勇避免掉被坑的地方，材料上面真的是一分价钱一分货；在装修合同签订前，家里......",
        },
        {
          title: "装修有哪些建议？",
          time: "2024-07-08",
          text: "作为一个刚转行的「从业者」 & 「事儿bi」，不回答这种问题简直对不起自己的职业；装修期间估计是很多人除了高三外，知识储备最丰富的时间段了。满世界......",
        },
      ],
      systemBottomList: ["强大团队", "实力验证", "安全保障", "开放能力"],
    };
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    new Array(15).fill("").forEach((_, index) => {
      this.logoList.push({
        logo: require(`@/assets/img/home/icon_partner_${index + 1}.png`),
      });
    });
    // this.navList = this.$store.state.navList;
  },
  computed: {
    navList() {
      return this.$store.state.navList;
    },
    title() {
      return this.$store.state.title;
    },
  },
  methods: {
    funyuming(msg) {
      this.$router.push(msg);
    },
    funRouter() {
      this.$router.push("/keji");
    },
  },
};
</script>
<style scoped lang="scss">
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.homepage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}

.banner_nav {
  width: 100vw;
  height: 800px;
  position: relative;
  background-color: #5e81f4;
}

.banner_nav_bg {
  text-align: center;
  img {
    margin: 0 auto;
    height: 800px;
    width: 1920px;
    // width: 100%;
    /* 文本居顶部对齐 */
    vertical-align: top;
  }
}

/* 导航部分 */

.navWrap {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*  */
  padding-top: 40px;
}

.navWrap_center {
  width: 1440px;
  height: 101px;
  margin: 0 auto;
  background: #fff;
  border-radius: 51px;
  /*  */
  display: flex;
  justify-content: space-between;
}

.navWrap_center_img {
  width: 181px;
  height: 54px;
  margin: 23px 0 0 120px;
}

.navWrap_center_img img {
  width: 181px;
}

.navWrap_center_ul {
  /* border:1px solid red; */
  margin-right: 121px;
}

.navWrap_center_ul li {
  display: inline-block;
  height: 98px;
  line-height: 98px;
  /* border-bottom: 4px solid blue; */
  margin-left: 50px;
  padding: 0 5px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  /* line-height: 22px; */
}
.navWrap_center_ul li:hover {
  cursor: pointer;
  color: rgba(35, 51, 127, 1);
  border-bottom: 2px solid rgba(35, 51, 127, 1);
}

/* title */

.banner_title_wrap {
  width: 100%;
  position: absolute;
  top: 281px;
  /* border: 1px solid yellow; */
}

.banner_title_center {
  width: 1200px;
  height: 124px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 89px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: 0 auto;
  .content {
    padding-top: 14px;
    font-size: 20px;
    font-family: Helvetica;
    line-height: 24px;
    letter-spacing: 4px;
  }
}

.banner_title_center div span {
  margin: 0 18px;
}
/* news */
.news {
  width: 100vw;
  height: 801px;
}
.news_cont {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.news_cont_left {
  margin-top: 89px;
}
.news_cont_left_img img {
  width: 588px;
  height: 353px;
  margin-top: 41px;
}
.news_cont_left_text {
  padding-top: 38px;
  padding-left: 40px;
  width: 548px;
  height: 150px;
  background-color: #ffffff;
  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
  }
}
.news_cont_left_text p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #8f8f8f;
  margin-top: 10px;
}
.news_cont_right {
  width: 588px;
  margin-top: 103px;
}
.news_cont_right_ul ul {
  font-size: 16px;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 22px;
  float: right;
  display: flex;
}
.news_cont_right_ul ul a {
  font-size: 16px;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 22px;
  float: right;
  display: flex;
}
.news_cont_right_ul ul li:hover {
  color: #23337f;
}
.news_cont_right_ul ul span {
  margin: 0 23px 0 24px;
}
.news_cont_right_text {
  width: 588px;
  height: 535px;
  background: #ffffff;
  margin-top: 68px;
}
.news_cont_right_text_item {
  padding: 37px 40px;
  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    text-align: left;
  }
}
.news_cont_right_text_item h1 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.news_cont_right_text_item p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #8f8f8f;
  line-height: 20px;
}
.news_cont_right_text_hr {
  /* float: right; */
  width: 508px;

  border-bottom: 1px solid #d7d7d7;
}
.news_cont_right_text_hr:nth-of-type(3) {
  /* float: right; */
  width: 508px;

  border-bottom: none;
}
.news_cont_right_text_item2 {
  padding: 24px 40px;
}
.news_cont_right_text_item2 h1 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.news_cont_right_text_item2 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 20px;
}
/* 集团产业 */
.GroupIndustry {
  width: 100vw;
  height: 800px;
  background: #ffffff;
}
.GroupIndustry_cont {
  width: 1200px;
  margin: 0 auto;
  gap: 24px;
  display: flex;
  justify-content: space-between;
}
.GroupIndustry_cont_left_title {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  height: 134px;
  line-height: 134px;
  // margin: 84px 0 40px 0;
  // margin-bottom: 40px;
}
.GroupIndustry_cont_left_img_f {
  width: 793px;

  display: flex;
  //   justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
}
.GroupIndustry_cont_left_img {
  //   margin-bottom: 24px;
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;
  position: relative;
  width: 384px;
  height: 261px;
}
.GroupIndustry_cont_left_img_shadow {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 130px;
  background: #000000;
  box-shadow: 2px 2px 10px 1px rgba(203, 212, 227, 0.22);
  opacity: 0.1;
}
.GroupIndustry_cont_left_img img:first-child {
  width: 100%;
  height: 100%;
  //   background: linear-gradient(180deg, rgba(0, 100, 255, 0) 0%, #000b6e 100%);
}
.GroupIndustry_cont_left_img2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.GroupIndustry_cont_left_text {
  position: absolute;
  //   left: 32px;
  bottom: 18px;
  width: 100%;
  text-align: center;
}
.GroupIndustry_cont_left_text p {
  font-size: 15px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 10px;
}
.GroupIndustry_cont_left_text span {
  font-size: 25px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.GroupIndustry_cont_right {
  position: relative;
  padding-top: 134px;
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;
}

.GroupIndustry_cont_right_img {
  width: 384px;
  height: 546px;
}
.GroupIndustry_cont_right_img2 {
  position: absolute;
  bottom: 24px;
}
.GroupIndustry_cont_right_text {
  position: absolute;
  left: 45px;
  bottom: 45px;
}
.GroupIndustry_cont_right_text p {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 33px;
}
.GroupIndustry_cont_right_text span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
/* 关于企业 */
.AboutEnterprises {
  width: 100vw;
  height: 856px;
  background-image: url("../assets/img/home/home_system_bcg.png");
}
.AboutEnterprises_cont {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}
.AboutEnterprises_cont_top {
  display: flex;
  justify-content: space-between;
}
.AboutEnterprises_cont_top_left {
  margin-top: 143px;
  width: 508px;
}
.AboutEnterprises_cont_top_left > h1 {
  font-size: 38px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 50px;
}
.AboutEnterprises_cont_top_left p {
  margin-top: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
}
.AboutEnterprises_cont_top_left_btn {
  width: 160px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  margin-top: 81px;
  display: flex;
  align-items: center;
}
.AboutEnterprises_cont_top_left_btn img {
  width: 24px;
  height: 24px;

  margin-left: 8px;
}
.AboutEnterprises_cont_top_left_btn span {
  margin-left: 32px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}
.AboutEnterprises_cont_top_right {
  position: relative;
  width: 487px;
  height: 517px;
  margin-top: 115px;
}
.AboutEnterprises_cont_top_right img {
  position: absolute;
  z-index: 1;
}
.AboutEnterprises_cont_top_right Div {
  width: 317px;
  height: 408px;
  background: #3491e5;
  position: absolute;
  top: 64px;
  left: -33px;
}
.AboutEnterprises_cont_bottom {
  display: flex;

  margin-top: 80px;
}
.AboutEnterprises_cont_bottom_item {
  margin-right: 70px;
  position: relative;
  width: 247px;
  border-right: 1px solid #ffffff;
  display: flex;
  p {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 22px;
    color: #ffffff;
  }
  span {
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
  }
}

.AboutEnterprises_cont_bottom_item h1 {
  font-size: 60px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 84px;
}

.AboutEnterprises_cont_bottom_item2 {
  position: absolute;
  left: 85px;
  bottom: 15px;
}
/* 合作伙伴 */
.CooperativePartner {
  width: 100vw;
  height: 798px;
  background-image: url("../assets/img/hezuo_bg.png");
}
.CooperativePartner_cont {
  width: 1200px;
  margin: 0 auto;
}
.CooperativePartner_cont_title {
  text-align: center;
  margin-top: 80px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 50px;
}
.CooperativePartner_cont_center {
  display: flex;
  justify-content: space-between;
  width: 365px;
  height: 69px;

  margin: 0 auto;
  margin-top: 47px;
}
.CooperativePartner_cont_center_item {
  display: flex;
  align-items: center;
}
.CooperativePartner_cont_center_item img {
  width: 40px;
  height: 40px;
}
.CooperativePartner_cont_center_item span {
  line-height: 69px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1f2329;
  line-height: 33px;
  margin-left: 14px;
}
.CooperativePartner_cont_bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1200px;
  height: 392px;
  margin: 0 auto;
  margin-top: 80px;
}
.CooperativePartner_cont_bottom_logo {
  img {
    width: 240px;
    height: 130px;
    // background: #ffffff;
    // box-shadow: inset 0px 0px 12px -2px rgba(0, 0, 0, 0.12);
    border-radius: 9px;
    margin-bottom: 16px;
  }
}
.dropbtn {
  background-color: #fff;
  color: rgb(12, 11, 11);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  top: 22px;
  left: 394px;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  text-align: center;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 95px;
  min-height: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: rgb(6, 6, 6);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
