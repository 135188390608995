<template>
  <div>
    <div class="wrap">
      <div class="logo">
        <img :src="logoimg" />
      </div>

      <div class="nav">
        <!-- <div class="dropdown">
          <button class="dropbtn" @click="funRouter">集团产业</button>
          <div class="dropdown-content">
            <router-link to="/keji">科技</router-link>
            <router-link to="/zhijiao">职教</router-link>
            <router-link to="/youjiao">幼教</router-link>
            <router-link to="/dianshang">电商</router-link>

            <router-link to="/jinrong">金融</router-link>
          </div>
        </div> -->
        <ul>
          <li
            v-for="(item, index) in navList"
            :key="index"
            @click="funyuming(item.url)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      logoimg: require("@/assets/img/logo_plain.png"),
    };
  },
  computed: {
    navList() {
      return this.$store.state.navList;
    },
  },
  methods: {
    funyuming(msg) {
      this.$router.push(msg);
    },
    funRouter() {
      this.$router.push("/keji");
    },
  },
};
</script>
<style scoped lang="scss">
.wrap {
  position: relative;
  width: 100vw;
  height: 101px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
}

.wrap > .logo > img {
  position: absolute;
  left: 360px;
  top: 23px;
  width: 181px;
  height: 54px;
}

.nav {
  //   width: 556px;
  position: absolute;
  right: 360px;
  height: 100%;
  ul {
    height: 100%;
    display: flex;
    gap: 80px;
    align-items: center;
    li {
      white-space: nowrap;
      display: flex;
      list-style-type: none;
      color: rgba(68, 68, 68, 1);
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: rgba(35, 51, 127, 1);
        border-bottom: 2px solid rgba(35, 51, 127, 1);
      }
    }
  }
}

.dropbtn {
  background-color: #fff;
  color: rgba(68, 68, 68, 1);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  top: 30px;
  left: 120px;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  z-index: 999;
  text-align: center;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 95px;
  min-height: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
