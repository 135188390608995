<template>
  <!-- 宽度占满，并且有加入的信封 -->
  <div>
    <div class="steps">
      <h1 class="steps-box-title">{{ title }}</h1>
      <img
        class="back-img"
        :src="require('@/assets/img/steps_bottom_backgounrd.png')"
        alt=""
      />
      <div class="steps-items flex flex-a flex-jc-sa">
        <div
          class="steps-item flex flex-a"
          v-for="(item, index) in stepList"
          :key="index"
        >
          <div
            class="steps-item-left"
            :style="{
              marginRight: index !== stepList.length - 1 ? itemSpace + 'px' : 0,
            }"
          >
            <img v-if="item.icon" class="item-icon" :src="item.icon" />
            <div class="text-top">{{ item.top }}</div>
            <div class="text-bottom">{{ item.bottom }}</div>
          </div>
          <img
            v-if="index !== stepList.length - 1"
            class="arrow"
            :style="{ marginRight: itemSpace + 'px' }"
            :src="arrowIcon || require('@/assets/img/arror_right_white.png')"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="cooperate">
      <img class="bcg" src="@/assets/img/蒙版.png" alt="" />
      <div class="decoration-box">
        <img src="@/assets/img/designerUnion/decoration.png" alt="" />
        <div class="decoration flex flex-dr flex-a">
          <div class="title">加入 , 开启您的好生意</div>
          <div class="content">
            我们为合作伙伴提供店铺的相对应系统管理，为您打造专属经营，通过平台优质的工具服务、团队服务，助力企业能力成长、实现业务超级增长。
          </div>
          <div class="bottom flex">
            <div class="button" @click="openDialog">立即注册</div>
            <!-- 跳pc端 -->
            <div class="button" @click="toPc">立即登录</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogShow" width="552px" top="200px">
      <div class="dialog">
        <div class="dialog-title">扫一扫下载“狸小住APP”</div>
        <div class="dialog-qrcode">
          <img class="img" src="@/assets/img/app_download_qrcode.png" alt="" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    stepList: {
      type: Array,
      require: true,
    },
    arrowIcon: {
      type: String,
      require: false,
    },
    itemSpace: {
      type: Number,
      default: 173,
    },
  },
  data() {
    return {
      dialogShow: false,
    };
  },
  methods: {
    openDialog() {
      this.dialogShow = true;
    },
    toPc() {
      const path = this.$route.fullPath;
      if (path.includes("supplier")) {
        window.location.href = "https://seller.lxzhu.cn";
      }
      if (path.includes("designer")) {
        window.location.href = "https://designer.lxzhu.cn";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
.steps {
  padding-top: 188px;
  .steps-box-title {
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    color: white;
    margin-bottom: 40px;
  }
  position: relative;
  .back-img {
    position: absolute;
    // opacity: 0.05;
    z-index: -1;
    top: 0;
    height: 100%;
    width: 100%;
  }
  height: 856px;
  &-items {
    width: 1200px;
    margin: 0 auto;
    // gap: 84px;
  }
  &-item {
    &-left {
      // margin-right: 84px;
      text-align: center;
      .text-top {
        font-weight: 500;
        font-size: 18px;
        color: white;
        margin-bottom: 8px;
      }
      .text-bottom {
        font-weight: 500;
        font-size: 14px;
        color: white;
      }
      .item-icon {
        width: 132px;
        height: 132px;
        margin-bottom: 16px;
      }
    }
    .arrow {
      width: 56px;
      height: 56px;
      // margin-right: 173px;
    }
  }
}
.cooperate {
  background-color: white;
  height: 315px;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  .bcg {
    position: absolute;
    opacity: 0.05;
    bottom: 0;
    left: 0;
    height: 302px;
    width: 1200px;
  }
  .decoration-box {
    position: relative;
    width: 100%;
    height: 443px;
    top: -220px;
    img {
      z-index: 1;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .decoration {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 400px;
      background-color: white;
      border-radius: 16px;
      text-align: center;
      .title {
        margin: 104px 0 20px;
        font-weight: bold;
        font-size: 34px;
        color: #1f2329;
      }
      .content {
        width: 682px;
        font-weight: 500;
        font-size: 14px;
        color: #444444;
        line-height: 30px;
        white-space: wrap;
        margin-bottom: 32px;
      }
      .bottom {
        z-index: 2;
        gap: 16px;
        .button {
          width: 162px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          background: #5e81f4;
          border-radius: 8px;
          color: white;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.dialog {
  height: 327px;
  text-align: center;
  &-title {
    font-weight: bold;
    font-size: 24px;
    color: #1f2329;
    margin-bottom: 40px;
  }
  &-qrcode {
    margin: 0 auto;
    width: 195px;
    height: 195px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
/deep/ .el-dialog {
  border-radius: 20px;
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
</style>
