<template>
  <!-- star -->

  <div class="youjiaopage_wrap manage">
    <nav2></nav2>
    <banner2
      class="banner"
      bannerH="供应商管理"
      :bannerP="bannerP"
      :bannerImg="require('@/assets/img/top_back.png')"
    ></banner2>
    <div class="main">
      <h1 class="box-title">狸小住商家平台</h1>
      <div class="platform flex">
        <div
          class="platform-item flex"
          v-for="(item, index) in platformList"
          :key="index"
        >
          <div class="platform-item-left flex1 flex flex-dr flex-a flex-jc">
            <div class="platform-item-top">{{ item.topContent }}</div>
            <div class="platform-item-bottom">
              {{ item.bottomContent }}
            </div>
          </div>
          <div class="platform-item-right flex1">
            <img :src="item.icon" alt="" />
          </div>
        </div>
      </div>

      <h1 class="box-title">店铺与经营</h1>
      <div class="operate flex">
        <div class="item" v-for="(item, index) in operate" :key="index">
          <img :src="item.icon" alt="" class="icon" />
          <div>{{ item.text }}</div>
        </div>
      </div>

      <div class="data-board flex">
        <div class="data-board-left">
          <div class="data-board-title">经营数据看板</div>
          <div class="data-board-text">
            提供直观的经营数据展示，诊断店铺经营健康状况，挖掘潜在用户，有效链接您的生意与真实业主
          </div>
          <div class="data-board-steps">
            <div
              v-for="(item, index) in dataBoardStepList"
              :key="index"
              class="item"
              :style="{
                borderRight:
                  index !== dataBoardStepList.length - 1
                    ? ' solid 1px #707070'
                    : 'none',
              }"
            >
              <span class="item-1">0{{ index + 1 }}</span>
              <span class="item-2">+</span>
              <span class="item-3">{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="data-board-right">
          <img src="@/assets/img/supplierManage/data_board_right.png" alt="" />
        </div>
      </div>
    </div>
    <BottomStepsFull
      title="认证步骤"
      :stepList="bottomStepList"
      :itemSpace="84"
    ></BottomStepsFull>

    <footer2></footer2>
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import BottomStepsFull from "@/components/BottomStepsFull.vue";
export default {
  name: "supplierManage",
  components: {
    footer2,
    nav2,
    banner2,
    BottomStepsFull,
  },
  data() {
    return {
      bannerP:
        "一站式商家智慧经营平台，为您提供店铺与团队的高效管理、内容与服务的长效经营，助力商家实现客户高增长，共赢商机新发展",
      Img1: require("../assets/img/keji/矿山banner1.png"),
      platformList: [
        {
          topContent: "合作入驻",
          bottomContent: "适配装企销售需求助力商家生意增长",
        },
        {
          topContent: "全新主页",
          bottomContent: "账号主页增加独立商品集合页",
        },
        {
          topContent: "商品曝光",
          bottomContent: "好物/榜单流量曝光算法推荐更精准",
        },
      ],
      operate: [
        {
          text: "经营数据看板",
        },
        {
          text: "智能团队管理",
        },
        {
          text: "客户与资源管理",
        },
        {
          text: "在线咨询",
        },
        {
          text: "私信小助手",
        },
      ],
      dataBoardStepList: ["周曝光次数", "周咨询人数", "转化成本低"],
      bottomStepList: [
        {
          top: "开通账号",
          bottom: "绑定真实手机号",
          icon: require(`@/assets/img/supplierManage/bottom_step_icon_1.png`),
        },
        {
          top: "提交认证资料",
          bottom: "上传真实有效信息",
          icon: require(`@/assets/img/designerUnion/step_icon_1.png`),
        },
        {
          top: "通过审核",
          bottom: "5个工作日完成审核",
          icon: require(`@/assets/img/designerUnion/step_icon_2.png`),
        },
        {
          top: "认证成功",
          bottom: "成为品牌/店铺账号",
          icon: require(`@/assets/img/supplierManage/bottom_step_icon_4.png`),
        },
      ],
    };
  },
  created() {
    this.platformList.forEach((_, index) => {
      this.$set(
        this.platformList[index],
        "icon",
        require(`@/assets/img/supplierManage/platform_icon_${index + 1}.png`)
      );
    });
    this.operate.forEach((_, index) => {
      this.$set(
        this.operate[index],
        "icon",
        require(`@/assets/img/supplierManage/operate_icon_${index + 1}.png`)
      );
    });
  },
};
</script>
<style scoped lang="scss">
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
div {
  box-sizing: border-box;
}
.manage {
  /deep/ .banner {
    margin-bottom: 40px;
    .banner_warp_text {
      position: initial;
      left: initial;
      top: initial;
      p {
        margin: 18px auto 0;
        width: 600px;
        white-space: wrap;
      }
    }
  }
  .main {
    margin: 0 auto;
    width: 1200px;
    .box-title {
      text-align: center;
      font-weight: bold;
      font-size: 38px;
      color: #1f2329;
      margin-bottom: 40px;
    }
    .platform {
      margin-bottom: 120px;
      gap: 20px;
      &-item {
        background-color: #f7f7ff;
        width: 386px;
        height: 145px;
        // padding: 36px 48px 0;
        &-top {
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 18px;
          color: #444444;
        }
        &-bottom {
          width: 126px;
          white-space: wrap;
          font-weight: 500;
          font-size: 14px;
          color: #999999;
        }
        &-right {
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .operate {
      font-weight: 500;
      font-size: 18px;
      gap: 128px;
      color: #444444;
      text-align: center;
      margin-bottom: 40px;
      .icon {
        width: 132px;
        height: 132px;
        margin-bottom: 16px;
      }
    }

    .data-board {
      height: 206px;
      margin-bottom: 120px;
      &-left {
        height: 100%;
        width: 873px;
      }
      &-title {
        font-size: 30px;
        color: #1f2329;
        margin-bottom: 20px;
      }
      &-text {
        font-weight: 500;
        font-size: 14px;
        color: #444444;
        margin-bottom: 42px;
      }
      &-steps {
        // position: absolute;
        // bottom: 71px;
        display: flex;
        gap: 25px;
        width: 246px;
        height: 84px;

        .item {
          height: 100%;
          width: 246px;
          min-width: 246px;
          text-align: left;
          color: #444;
        }

        .item-1 {
          // color: rgba(35, 51, 127, 1);
          font-size: 60px;
        }

        .item-2 {
          position: absolute;
          // color: rgba(35, 51, 127, 1);
          font-size: 16.8px;
        }

        .item-3 {
          // position: absolute;
          // left: 88px;
          margin-top: 41px;
          // color: rgba(102, 102, 102, 1);
          font-size: 22px;
          // width: 100px;
        }
      }
    }
  }
}
</style>
