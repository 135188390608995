import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import youjiao from "../views/youjiao.vue";
import zhijiao from "../views/SupplierManage.vue";
import news from "../views/news.vue";
import GroupNews from "../views/News/GroupNews.vue";
import MediaCoverage from "../views/News/MediaCoverage.vue";
import TradeNews from "../views/News/TradeNews.vue";
import newsDetail from "../views/newsxiangqing.vue";
import newsDetail1 from "../views/NewsDetals/newsxiangqing1.vue";
import newsDetail2 from "../views/NewsDetals/newsxiangqing2.vue";
import MediaCoverage0 from "../views/NewsDetals/MediaCoverage0.vue";
import MediaCoverage1 from "../views/NewsDetals/MediaCoverage1.vue";
import TradeNews0 from "../views/NewsDetals/TradeNews0.vue";
import TradeNews1 from "../views/NewsDetals/TradeNews1.vue";
import zhaopinxinxi0 from "../views/zhaopinxinxi0.vue";
import zhaopinxinxi1 from "../views/Recruit-info/zhaopinxinxi1.vue";
import zhaopinxiangqing0_1 from "../views/Recruitment-details/zhaopinxiangqing0-1.vue";
import zhaopinxiangqing0_0 from "../views/Recruitment-details/zhaopinxiangqing0-0.vue";
import zhaopinxiangqing1_0 from "../views/Recruitment-details/zhaopinxiangqing1-0.vue";
import zhaopinxiangqing1_1 from "../views/Recruitment-details/zhaopinxiangqing1-1.vue";
import userPrivacy from "../views/NewsDetals/userPrivacy.vue";
import workerPrivacy from "../views/NewsDetals/workerPrivacy.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/serviceAssurance",
    name: "serviceAssurance",
    component: () => import("@/views/ServiceAssurance.vue"),
    meta: {
      title: "服务保障",
    },
  },
  {
    path: "/blueUnion",
    name: "blueUnion",
    component: () => import("@/views/BlueUnion.vue"),
    meta: {
      title: "蓝友联盟",
    },
  },
  {
    path: "/youjiao",
    name: "youjiao",
    component: youjiao,
    meta: {
      title: "幼教",
    },
  },
  {
    path: "/supplierManage",
    name: "supplierManage",
    component: () => import("@/views/SupplierManage.vue"),
    meta: {
      title: "供应商管理",
    },
  },
  {
    path: "/zhijiao",
    name: "zhijiao",
    component: zhijiao,
    meta: {
      title: "执教",
    },
  },
  {
    path: "/designerUnion",
    name: "designerUnion",
    component: () => import("@/views/DesignerUnion.vue"),
    meta: {
      title: "设计师联盟",
    },
  },
  {
    path: "/xinwen",
    name: "xinwen",
    component: news,
    children: [
      {
        path: "",
        component: GroupNews,
        name: "GroupNews",
        meta: {
          title: "集团新闻",
        },
      },
      {
        path: "MediaCoverage",
        component: MediaCoverage,
        name: "MediaCoverage",
        meta: {
          title: "媒体报道",
        },
      },
      {
        path: "TradeNews",
        name: "TradeNews",
        component: TradeNews,
        meta: {
          title: "行业资讯",
        },
      },
    ],
    meta: {
      title: "集团新闻",
    },
  },
  {
    path: "/newsxiangqing0",
    name: "newsDetal",
    component: newsDetail,
    meta: {
      title: "新闻详情",
    },
  },
  {
    path: "/userPrivacy",
    name: "userPrivacy",
    component: userPrivacy,
    meta: {
      title: "用户隐私政策",
    },
  },
  {
    path: "/workerPrivacy",
    name: "workerPrivacy",
    component: workerPrivacy,
    meta: {
      title: "工人隐私政策",
    },
  },
  {
    path: "/newsxiangqing1",
    name: "newsDetal1",
    component: newsDetail1,
    meta: {
      title: "新闻详情1",
    },
  },
  {
    path: "/newsxiangqing2",
    name: "newsDetal2",
    component: newsDetail2,
    meta: {
      title: "新闻详情2",
    },
  },
  {
    path: "/MediaCoverage0",
    name: "MediaCoverage0",
    component: MediaCoverage0,
    meta: {
      title: "媒体报道0",
    },
  },
  {
    path: "/MediaCoverage1",
    name: "MediaCoverage1",
    component: MediaCoverage1,
    meta: {
      title: "媒体报道1",
    },
  },
  {
    path: "/TradeNews0",
    name: "TradeNews0",
    component: TradeNews0,
    meta: {
      title: "行业资讯0",
    },
  },
  {
    path: "/TradeNews1",
    name: "TradeNews1",
    component: TradeNews1,
    meta: {
      title: "行业资讯1",
    },
  },
  {
    path: "/zhaopinxinxi0",
    name: "zhaopinxinxi0",
    component: zhaopinxinxi0,
    meta: {
      title: "招聘信息0",
    },
  },
  {
    path: "/zhaopinxinxi1",
    name: "zhaopinxinxi1",
    component: zhaopinxinxi1,
    meta: {
      title: "招聘信息1",
    },
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("@/views/AboutUs.vue"),
    meta: {
      title: "联系我们",
    },
  },
  {
    path: "/zhaopinxiangqing0_0",
    name: "zhaopinxiangqing0_0",
    component: zhaopinxiangqing0_0,
    meta: {
      title: "招聘详情0-0",
    },
  },
  {
    path: "/zhaopinxiangqing0_1",
    name: "zhaopinxiangqing0_1",
    component: zhaopinxiangqing0_1,
    meta: {
      title: "招聘详情0-1",
    },
  },
  {
    path: "/zhaopinxiangqing1_0",
    name: "zhaopinxiangqing1_0",
    component: zhaopinxiangqing1_0,
    meta: {
      title: "招聘详情1-0",
    },
  },
  {
    path: "/zhaopinxiangqing1_1",
    name: "zhaopinxiangqing1_1",
    component: zhaopinxiangqing1_1,
    meta: {
      title: "招聘详情1-1",
    },
  },
];
// title

const router = new VueRouter({
  routes,
  // 跳转页面后 回到顶部
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle;
  next();
});
